import { FC, useEffect } from 'react'
import { Space, Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { useStoreState, useStoreActions } from 'stores'
import TranslatedLink from 'core/components/TranslatedLink/TranslatedLink'
import WebhooksContentDisplay from './components/WebhooksContentDisplay'
import WebhookForm from './components/WebhookForm'
import styles from './WebhooksContainer.less'

interface Props {
  teamId: number
}

const WebhooksContainer: FC<Props> = ({ teamId }) => {
  const { t } = useTranslation()

  const { webhooks, webhookHTTPStatus } = useStoreState(
    (state) => state.webhooks
  )

  const { getWebhooks } = useStoreActions((actions) => actions.webhooks)

  useEffect(() => {
    if (!webhooks) {
      getWebhooks(teamId)
    }
  }, [webhooks, teamId, getWebhooks])

  return (
    <section data-test-id="webhooks-tab">
      <section>
        <div className={styles.descriptionContainer}>
          <Text size="lg">{t('settingsPage.webhooksContainer.title')}</Text>

          <Space size="sm" />

          <Text size="md">
            <TranslatedLink
              i18nKey="settingsPage.webhooksContainer.description"
              url="https://developer.signifyd.com/api/#/reference/webhooks"
            />
          </Text>
        </div>

        <Space size="md" />

        <WebhookForm teamId={teamId} webhookHTTPStatus={webhookHTTPStatus} />
      </section>

      <Space size="lg" />

      <section>
        <Text size="lg">
          {t('settingsPage.webhooksContainer.form.usersWebhooksTitle')}
        </Text>

        <Space size="md" />

        <WebhooksContentDisplay
          webhookHTTPStatus={webhookHTTPStatus}
          webhooks={webhooks}
        />
      </section>
    </section>
  )
}

export default WebhooksContainer
