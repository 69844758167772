import { FC, ReactElement, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, Button } from 'antd'
import { WebhookData } from '@signifyd/http'
import { Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { useStoreActions } from 'stores'
import { WEBHOOK_STATUS_LABEL } from 'core/constants'
import { matchParenthesisAndContentWithin } from 'core/utils/regularExpressions'
import { TestStatus } from './WebhookTestPanel.config'
import styles from './WebhookTestPanel.less'

interface Props {
  webhook: WebhookData
}

const WebhookTestPanel: FC<Props> = ({ webhook }) => {
  const [retrievingTest, setRetrievingTest] = useState<boolean>(false)
  const [testResponseMessage, setTestResponseMessage] = useState<
    ReactElement | string
  >('')

  const { testWebhook } = useStoreActions((actions) => actions.webhooks)

  const { t } = useTranslation()

  const onTestWebhook = (): void => {
    setRetrievingTest(true)
    testWebhook({
      url: webhook.url,
      eventType: webhook.eventType,
    }).then((response) => {
      const { status, statusText, data } = response

      if (!status && !data?.messages) {
        setTestResponseMessage(TestStatus.other)
      } else if (status <= 202) {
        setTestResponseMessage(TestStatus.success(status, statusText))
      } else if (data.messages) {
        const message = response.data.messages[0]
        const messageStatus = matchParenthesisAndContentWithin(message)

        if (messageStatus) {
          const statusNumber = messageStatus.slice(1, -1)

          if (
            WEBHOOK_STATUS_LABEL[
              statusNumber as keyof typeof WEBHOOK_STATUS_LABEL
            ]
          ) {
            setTestResponseMessage(TestStatus.error(statusNumber))
          } else {
            setTestResponseMessage(TestStatus.other)
          }
        } else {
          setTestResponseMessage(TestStatus.raw(data.messages[0]))
        }
      }

      setRetrievingTest(false)
    })
  }

  if (retrievingTest) {
    return (
      <div data-test-id="test-panel-loading-state" className={styles.loading}>
        <Spin indicator={<LoadingOutlined spin />} />
        <Text className={styles.text}>
          {t('settingsPage.webhooksContainer.testPanel.loadingText')}
        </Text>
      </div>
    )
  }

  return (
    <section className={styles.testPanel}>
      <Button
        className={styles.button}
        data-test-id="test-button"
        type="link"
        onClick={onTestWebhook}
      >
        {t('settingsPage.webhooksContainer.testPanel.testButton')}
      </Button>
      {testResponseMessage && <Text>{testResponseMessage}</Text>}
    </section>
  )
}

export default WebhookTestPanel
