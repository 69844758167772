import { listWebhooksForTeam, deleteWebhook, testWebhook } from '@signifyd/http'
import { action, thunk } from 'easy-peasy'
import { i18nInstance } from '@signifyd/components'
import getSigErrorMessage from 'core/utils/getSigErrorMessage'
import { WebhooksModel } from './types'

const { t } = i18nInstance

const defaultHttpStatus = {
  type: null,
  loading: false,
  error: null,
}

const webhooksModel: WebhooksModel = {
  // State
  webhooks: null,
  webhookHTTPStatus: { ...defaultHttpStatus },

  // Actions
  setWebhooks: action((state, webhooks) => {
    state.webhooks = webhooks
  }),
  addWebhook: action((state, webhook) => {
    if (state.webhooks) {
      state.webhooks.push(webhook)
    } else {
      state.webhooks = [webhook]
    }
  }),
  removeWebhookById: action((state, webhookId) => {
    if (state.webhooks) {
      state.webhooks = state.webhooks.filter(
        (webhook) => webhook.id !== webhookId
      )
    }
  }),
  setWebhookHTTPStatus: action((state, newState) => {
    state.webhookHTTPStatus = { ...defaultHttpStatus, ...newState }
  }),

  // HTTP Stuff
  getWebhooks: thunk((actions, teamId) => {
    actions.setWebhookHTTPStatus({ type: 'GET', loading: true })

    return listWebhooksForTeam(teamId)
      .then(({ data }) => {
        actions.setWebhooks(data)
        actions.setWebhookHTTPStatus({ type: 'GET', loading: false })
      })
      .catch((err) => {
        const error =
          getSigErrorMessage(err) ??
          t('settingsPage.webhooksContainer.fallbackErrorMessage.getWebhooks')

        actions.setWebhookHTTPStatus({ type: 'GET', loading: false, error })
      })
  }),
  deleteWebhook: thunk((actions, webhookId) => {
    return deleteWebhook(webhookId).then(() =>
      actions.removeWebhookById(webhookId)
    )
  }),
  testWebhook: thunk((_actions, webhook) => {
    return testWebhook(webhook).catch((err) => err.response)
  }),
}

export default webhooksModel

// TODO FET-1860 https://signifyd.atlassian.net/browse/FET-1860
// add proper error handling
