import { USER_ROLE, WEBHOOK_EVENT } from '@signifyd/http'

// Hide non merchant provided values in JSON view for non admins
export const JSONViewerFieldsToOmit = [
  'platformAndClient',
  'createdAtInstant',
  'purchase.giftCardAmount',
  'purchase.creditCardAmount',
  'purchase.orderOutcome',
  {
    fieldName: 'purchase.checkoutToken',
    exceptionRoles: [USER_ROLE.DEVTOOLS_BETA, USER_ROLE.USER],
  },
  'purchase.transactionCurrency',
  'purchase.transactionTotalAmount',
]

export const dataQualityPanelMissingFieldsToOmit = ['Device Fingerprinting']

export const DEFAULT_PAGINATION_LIMIT = 25

export const WEBHOOK_LABEL = {
  [WEBHOOK_EVENT.CASE_CREATION]: 'Cases/Created',
  [WEBHOOK_EVENT.CASE_RESCORE]: 'Cases/Scored',
  [WEBHOOK_EVENT.CASE_REVIEW]: 'Cases/Flagged',
  [WEBHOOK_EVENT.GUARANTEE_COMPLETION]: 'Guarantees/Reviewed',
  [WEBHOOK_EVENT.CLAIM_PAYOUT]: 'Claims/Paid',
  [WEBHOOK_EVENT.CLAIM_REVIEWED]: 'Claims/Reviewed',
  [WEBHOOK_EVENT.GUARANTEE_INELIGIBLE]: 'Guarantees/Ineligible',
  [WEBHOOK_EVENT.DECISION_MADE]: 'Decisions/*',
}

export const WEBHOOK_STATUS_LABEL = {
  '204': 'No Content',
  '300': 'Multiple Choices',
  '301': 'Moved Permanently',
  '302': 'Found',
  '303': 'See Other',
  '304': 'Not Modified',
  '400': 'Bad Request',
  '401': 'Unauthorized',
  '403': 'Forbidden',
  '404': 'Not Found',
  '501': 'Not Implemented',
  '502': 'Bad Gateway',
  '503': 'Service Unavailable',
  '504': 'Gateway Timeout',
}

export const ORDERS_APP_URL =
  process.env.BUILD_ENV === 'production'
    ? 'https://console.signifyd.com/orders'
    : 'https://console.staging.signifyd.com/orders'

export const OLD_CONSOLE_URL =
  process.env.BUILD_ENV === 'production'
    ? 'https://app.signifyd.com/cases'
    : 'https://app.staging.signifyd.com/cases'
