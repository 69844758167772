import { P, T4 } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Popover, Row, Button, message } from 'antd'
import { useStoreActions } from 'stores'
import styles from './DeleteWebhookPopover.less'

interface Props {
  id: number
}

const openWebhookDeletionToast = (toastMessage: string): void => {
  const close = message.success(
    <span>
      {toastMessage}
      <span className="closeNotification" onClick={() => close()} />
    </span>,
    2
  )
}

const DeleteWebhookPopover: React.FC<Props> = ({ id }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [isWebhookDeleting, setIsWebhookDeleting] = useState(false)

  const { t } = useTranslation()

  const { deleteWebhook } = useStoreActions((actions) => actions.webhooks)

  const handleDeleteWebhook = async (id: number): Promise<void> => {
    setIsWebhookDeleting(true)

    await deleteWebhook(id)

    openWebhookDeletionToast(
      t('settingsPage.webhooksContainer.popover.toastMessage')
    )
  }

  return (
    <Popover
      destroyTooltipOnHide
      placement="left"
      overlayClassName={styles.popover}
      data-test-id="delete-webhook-popover"
      title={
        <>
          <T4>{t('settingsPage.webhooksContainer.popover.title')}</T4>
          <P>{t('settingsPage.webhooksContainer.popover.description')}</P>
        </>
      }
      content={
        <Row data-test-id="delete-webhook-content" justify="space-between">
          <Button
            data-test-id="delete-webhook-cancel-button"
            className={styles.popoverButton}
            type="link"
            disabled={isWebhookDeleting}
            onClick={() => setIsPopoverVisible(false)}
          >
            {t('settingsPage.webhooksContainer.popover.cancelButton')}
          </Button>
          <Button
            data-test-id="deleteWebhookButton"
            className={styles.popoverButton}
            type="link"
            disabled={isWebhookDeleting}
            onClick={() => handleDeleteWebhook(id)}
          >
            {t('settingsPage.webhooksContainer.popover.deleteButton')}
          </Button>
        </Row>
      }
      open={isPopoverVisible}
    >
      <DeleteOutlined
        data-test-id="deleteWebhookIcon"
        className={styles.deleteIcon}
        onClick={() => setIsPopoverVisible(true)}
      />
    </Popover>
  )
}

export default DeleteWebhookPopover
