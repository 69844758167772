import { ReactElement } from 'react'
import { joinClassNames } from '@signifyd/utils'
import { P } from '@signifyd/components'
import { ExclamationCircleFilled } from '@ant-design/icons'
import i18next from 'i18next'
import { WEBHOOK_STATUS_LABEL } from 'core/constants'
import styles from './WebhookTestPanel.less'

export const TestStatus = {
  success: (status: number, statusText: string): ReactElement => (
    <P className={joinClassNames([styles.status, styles.success])}>
      {status} - {statusText}
    </P>
  ),
  error: (status: string): ReactElement => (
    <P className={joinClassNames([styles.status, styles.error])}>
      {status} -{' '}
      {WEBHOOK_STATUS_LABEL[status as keyof typeof WEBHOOK_STATUS_LABEL]}
    </P>
  ),
  other: (
    <P
      data-test-id="other-message"
      className={joinClassNames([styles.status, styles.error])}
    >
      <ExclamationCircleFilled className={styles.errorIcon} />
      {i18next.t('settingsPage.webhooksContainer.testPanel.otherMessage')}
    </P>
  ),
  raw: (status: number): ReactElement => (
    <P className={joinClassNames([styles.status, styles.error])}>{status}</P>
  ),
}
