import { WebhookData } from '@signifyd/http'
import { Alert, Table } from 'antd'
import { Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import LoadingSpinner from 'core/components/LoadingSpinner'
import { WebhookHTTPStatus } from 'stores/webhooks'
import DeleteWebhookPopover from '../DeleteWebhookPopover'
import WebhookTestPanel from '../WebhookTestPanel'
import styles from './WebhooksContentDisplay.less'

interface Props {
  webhookHTTPStatus: WebhookHTTPStatus
  webhooks: Array<WebhookData> | null
}

const columnConfig: Array<ColumnProps<WebhookData>> = [
  {
    title: 'WEBHOOK ADDRESS',
    dataIndex: 'url',
  },
  {
    title: 'EVENT TYPE',
    dataIndex: 'eventType',
  },
  {
    width: '40%',
    title: 'ACTION',
    render: (_text, record) => <WebhookTestPanel webhook={record} />,
  },
  {
    dataIndex: 'id',
    render: (text) => <DeleteWebhookPopover id={text} />,
  },
]

const WebhooksContentDisplay: React.FC<Props> = ({
  webhookHTTPStatus,
  webhooks,
}) => {
  const { t } = useTranslation()

  if (webhookHTTPStatus.loading && webhookHTTPStatus.type === 'GET') {
    return <LoadingSpinner />
  }

  if (webhookHTTPStatus.error) {
    return (
      <Alert
        message={webhookHTTPStatus.error}
        type="error"
        showIcon
        style={{ width: '664px' }}
      />
    )
  }

  if (webhooks?.length === 0) {
    return (
      <Text
        size="sm"
        data-test-id="no-webhooks-placeholder"
        className={styles.noWebhooksPlaceholder}
      >
        {t('settingsPage.webhooksContainer.noWebhooksPlaceholder')}
      </Text>
    )
  }

  return (
    <Table
      columns={columnConfig}
      dataSource={webhooks || []}
      pagination={false}
      rowKey={(item) => item.id.toString()}
    />
  )
}

export default WebhooksContentDisplay
