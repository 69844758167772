import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { createWebhook, WebhookData, WebhookDataRequest } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { useStoreActions } from 'stores'
import getSigErrorMessage from 'core/utils/getSigErrorMessage'

interface WebhookPayload {
  teamId: number
  webhook: WebhookDataRequest
}

export const useCreateWebhook = (
  teamId: number
): UseMutationResult<WebhookData, AxiosError, WebhookPayload, unknown> => {
  const { t } = useTranslation()

  const { setWebhookHTTPStatus, addWebhook } = useStoreActions(
    (actions) => actions.webhooks
  )

  return useMutation<WebhookData, AxiosError, WebhookPayload, unknown>({
    mutationKey: ['createWebhook', teamId],
    mutationFn: async (webhookPayload: WebhookPayload) => {
      const { teamId, webhook } = webhookPayload
      setWebhookHTTPStatus({ type: 'CREATE', loading: true })

      const { data } = await createWebhook(webhook, teamId)
      addWebhook(data)
      setWebhookHTTPStatus({ type: 'CREATE', loading: false })

      return data
    },
    onError: (err) => {
      const error =
        getSigErrorMessage(err) ??
        t('settingsPage.webhooksContainer.fallbackErrorMessage.createWebhook')

      setWebhookHTTPStatus({ type: 'CREATE', loading: false, error })
    },
  })
}
