export default {
  invalidTeamWarning:
    'Invalid URL provided. Please choose a Team on this page to view Settings.',
  header: {
    backNavigation: 'Back to Teams',
  },
  tabs: {
    teamDetails: 'Team Details',
    webhooks: 'Webhooks',
    integrations: 'Integrations',
    deleteTeam: 'Delete Team',
  },
  editTeamContainer: {
    title: 'Your online store',
    description:
      "We'll use the additional store details you provide to provide resources to help you integrate faster.",
    cancelButton: 'Cancel',
    submitButton: 'Save',
    editButton: 'Edit',
    errorMessage: 'Error trying to update Team {{teamName}}',
    successMessage:
      'Details for {{storeName}} were updated successfully. Any updates to teams may take a few minutes to take effect. Check back later to view any changes.',
  },
  integrationsContainer: {
    title: 'Integrations to your online store',
    description:
      'Signifyd supports deep integration with payment gateways. You benefit from integrations by allowing Signifyd to include critical card result code information for each processed transaction within its fraud processing and guarantee decision.',
    authDotNet: {
      title: 'Authorize.net',
      description:
        "Improve the accuracy and speed of Signifyd's Guarantee decision by syncing transaction details like AVS and CVV response codes.<br /><externalLink>Learn more</externalLink>",
      credentials: {
        title: 'Credentials',
        updateButton: 'Update credentials',
        cancelButton: 'Cancel and keep current credentials',
      },
      loginId: 'API Login ID',
      loginIdPlaceholder: 'Enter ID',
      transactionKey: 'Transaction Key',
      transactionKeyPlaceholder: 'Enter key',
      submitButton: 'Submit',
      feedback: {
        loading: 'Retrieving results...',
        success: 'Successfully verified credentials',
      },
    },
    fallbackErrorMessage: {
      getGateways: 'Error trying to list gateways.',
      submitGateway: 'Error trying to submit gateway.',
      verifyGateway: 'Error trying to verify gateway.',
    },
  },
  deleteTeam: {
    title: 'Delete Team',
    alert: 'This action is permanent and cannot be undone.',
    description1:
      'Once you delete a team, the API key will be disabled and you will be unable to perform any team actions or view any cases created by this team.',
    description2:
      'Users associated with this team will retain access to the Signifyd app.',
    description3: 'To continue, please confirm by entering team name below.',
    placeholder: 'Enter exact team name',
    deleteButton: 'Delete Team',
    errorMessage: 'Error trying to delete Team {{teamName}}',
    successMessage:
      'Team successfully deleted {{teamName}}. Any updates to teams may take a few minutes to take effect. Check back later to view any changes.',
  },
  webhooksContainer: {
    title: 'Set up a webhook',
    description: `Use webhooks to be notified about events associated with this team by posting JSON notifications to a given URL. 
      Add webhook below and test. If you need help, check our <externalLink>API Docs.</externalLink>`,
    noWebhooksPlaceholder: 'No webhooks yet.',
    form: {
      eventTypePlaceholder: 'Select one',
      webhookAddressLabel: 'Webhook Address',
      webhookAddressTooltip:
        'This is the URL where Signifyd will POST the webhook data. It will be in JSON format.',
      eventTypeInputLabel: 'Event Type',
      eventTypeInputTooltip:
        'Each time the chosen event occurs we will send a webhook.',
      addButton: 'Add',
      usersWebhooksTitle: 'Your webhooks',
    },
    popover: {
      title: 'Delete Webhook',
      description:
        'By deleting this webhook, you will no longer receive notifications to the given URL.',
      deleteButton: 'Delete',
      cancelButton: 'Cancel',
      toastMessage: 'Webhook was successfully deleted.',
    },
    testPanel: {
      testButton: 'Test',
      loadingText: 'Retrieving test results…',
      otherMessage: 'Error while attempting test. Try again in a while.',
    },
    fallbackErrorMessage: {
      getWebhooks: 'Error trying to list webhooks.',
      createWebhook: 'Error trying to create webhook.',
    },
  },
}
