import { createStore, createTypedHooks } from 'easy-peasy'
import { userModel, UserModel } from './users'
import { WebhooksModel, webhooksModel } from './webhooks'
import { integrationsModel, IntegrationsModel } from './integrations'

export interface StoreModel {
  user: UserModel
  webhooks: WebhooksModel
  integrations: IntegrationsModel
}

const store = createStore({
  user: userModel,
  webhooks: webhooksModel,
  integrations: integrationsModel,
})

export const { useStoreActions, useStoreDispatch, useStoreState } =
  createTypedHooks<StoreModel>()

export default store
