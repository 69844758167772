import { FC } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Form as AntForm } from '@ant-design/compatible'
import { Tooltip, Button, Row, Select as AntSelect } from 'antd'
import { WEBHOOK_EVENT } from '@signifyd/http'
import { Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { FormikContext, useFormik } from 'formik'
import * as yup from 'yup'
import { useCreateWebhook } from 'core/queries/useCreateWebhook'
import { WebhookHTTPStatus } from 'stores/webhooks'
import Form, {
  FormikSubmitFunction,
  FormItem,
  Input,
  Select,
} from 'core/components/Form'
import styles from './WebhookForm.less'

const { Item } = AntForm

interface FormValues {
  eventType?: WEBHOOK_EVENT
  url: string
}

interface Props {
  teamId: number
  webhookHTTPStatus: WebhookHTTPStatus
}

const validationSchema = yup.object().shape({
  url: yup
    .string()
    .required('Url is required')
    .matches(/^https:\/\//, 'Must start with https://'),
  eventType: yup.string().required('Event type is required'),
})

const WebhookForm: FC<Props> = ({ teamId, webhookHTTPStatus }) => {
  const { t } = useTranslation()

  const { mutate: createWebhook } = useCreateWebhook(teamId)

  const handleSubmit: FormikSubmitFunction<FormValues> = (values): void => {
    const { eventType, url } = values

    createWebhook({
      teamId,
      webhook: {
        url,
        eventType: eventType!,
      },
    })
  }

  const formikInstance = useFormik<FormValues>({
    initialValues: {
      url: '',
      eventType: undefined,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
  })

  const { url, eventType } = formikInstance.values

  const isSubmitting =
    webhookHTTPStatus.type === 'CREATE' && webhookHTTPStatus.loading

  const disableSubmit =
    !url ||
    !eventType ||
    Object.keys(formikInstance.errors).length > 0 ||
    isSubmitting

  return (
    <FormikContext.Provider value={formikInstance}>
      <Form onSubmit={formikInstance.handleSubmit} layout="vertical">
        <Row align="top" className={styles.row}>
          <FormItem
            name="url"
            className={styles.label}
            label={
              <span>
                <Text size="sm" className={styles.text}>
                  {t('settingsPage.webhooksContainer.form.webhookAddressLabel')}
                </Text>
                <Tooltip
                  overlayClassName={styles.tooltipOverlay}
                  overlay={t(
                    'settingsPage.webhooksContainer.form.webhookAddressTooltip'
                  )}
                >
                  <QuestionCircleOutlined className={styles.icon} />
                </Tooltip>
              </span>
            }
          >
            <Input name="url" data-test-id="urlInput" placeholder="https://" />
          </FormItem>

          <FormItem
            className={styles.label}
            name="eventType"
            label={
              <span>
                <Text size="sm" className={styles.text}>
                  {t('settingsPage.webhooksContainer.form.eventTypeInputLabel')}
                </Text>
                <Tooltip
                  overlayClassName={styles.tooltipOverlay}
                  overlay={t(
                    'settingsPage.webhooksContainer.form.eventTypeInputTooltip'
                  )}
                >
                  <QuestionCircleOutlined className={styles.icon} />
                </Tooltip>
              </span>
            }
          >
            <Select
              data-test-id="webhookEventTypeSelect"
              name="eventType"
              placeholder={t(
                'settingsPage.webhooksContainer.form.eventTypePlaceholder'
              )}
            >
              {Object.values(WEBHOOK_EVENT).map((eventType) => (
                <AntSelect.Option key={eventType}>{eventType}</AntSelect.Option>
              ))}
            </Select>
          </FormItem>

          <Item label={<></>}>
            <Button
              type="primary"
              data-test-id="createWebhookButton"
              htmlType="submit"
              loading={isSubmitting}
              disabled={disableSubmit}
            >
              {t('settingsPage.webhooksContainer.form.addButton')}
            </Button>
          </Item>
        </Row>
      </Form>
    </FormikContext.Provider>
  )
}

export default WebhookForm
